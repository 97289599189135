












































































































































import Component, { mixins } from 'vue-class-component';
import WidgetMixins from '../../../mixins/WidgetMixins';
import DialogMixins from '../../../mixins/DialogMixins';
import { currencyFormat, loadView, loadWidget } from '@/utils/helpers';
import { namespace } from 'vuex-class';
import { IProduct, IStore, IUser } from '@/types/types';
import timestamp from '@/filters/timestamp';
import mediumZoom from 'medium-zoom';
import { Watch } from 'vue-property-decorator';
import { use } from 'chai';
const productsModule = namespace('products');
const authModule = namespace('auth');
@Component({
  name: 'ProductIndex',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
  },
  filters: {
    currencyFormat,
    timestamp,
  },
})
export default class ProductIndex extends mixins(WidgetMixins, DialogMixins) {
  @productsModule.Getter('getAllProducts') products!: IProduct[];
  @authModule.Getter('getUserDetails') details!: IUser & IStore;
  menu: boolean = false;
  headers: Array<{ text: string; value: string }> = [
    {
      text: '',
      value: 'logo',
    },
    {
      text: 'Name',
      value: 'name',
    },
    {
      text: 'Link',
      value: 'url',
    },
    {
      text: 'Admin Sub Path',
      value: 'appSubPath',
    },
    {
      text: 'Status',
      value: 'status',
    },
    {
      text: 'Actions',
      value: 'actions',
    },
  ];

  copyStoreURL(link: string) {
    this.$copyText(link)
      .then(data => {
        this.$store.dispatch('snackBarMessage', `Copied ${data.text}`, {
          root: true,
        });
        this.$store.dispatch('snackBarVisibility', true, { root: true });
      })
      .catch(error => {
        this.$store.dispatch('snackBarMessage', error, { root: true });
        this.$store.dispatch('snackBarVisibility', true, { root: true });
      });
  }

  openLink(url: string) {
    if (url) {
      let a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.click();
    }
  }

  openApp(id: string, app: string) {
    const appUrl = app?.replace('/', '');
    this.$store.dispatch('navigation/setAppLinks', appUrl);
    this.$router
      .push({
        path: `/products/${appUrl}/dashboard?appId=${id}`,
      })
      .catch(() => {});
  }

  created(): void {
    this.$store.dispatch('products/list');
    this.$store.dispatch('auth/me');
  }
  mounted(): void {
    mediumZoom('.productPreview');
  }
}
